function SvgChainOecLogo(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.155a4 4 0 014 0l8.124 4.69a4 4 0 012 3.464v9.382a4 4 0 01-2 3.464L16 26.845a4 4 0 01-4 0l-8.125-4.69a4 4 0 01-2-3.465V9.31a4 4 0 012-3.465L12 1.155z"
        fill="#fff"
      />
      <path
        d="M13.139 13.139a3.62 3.62 0 001.723 0 3.627 3.627 0 012.656-2.656A3.62 3.62 0 0014 6a3.622 3.622 0 00-3.518 4.482 3.628 3.628 0 012.657 2.656z"
        fill="#7ABDF7"
      />
      <path
        d="M14.862 14.86a3.62 3.62 0 00-1.723 0 3.628 3.628 0 01-2.656 2.657 3.623 3.623 0 107.035 0 3.626 3.626 0 01-2.656-2.656z"
        fill="#0D74F5"
      />
      <path
        d="M18.38 10.378c-.29 0-.58.035-.861.104a3.625 3.625 0 01-2.656 2.656 3.62 3.62 0 000 1.723 3.626 3.626 0 012.656 2.656 3.622 3.622 0 10.86-7.14v.001z"
        fill="#4494F7"
      />
      <path
        d="M13.14 14.86a3.623 3.623 0 000-1.722 3.626 3.626 0 01-2.657-2.656 3.623 3.623 0 10-1.625 7.058 3.623 3.623 0 001.625-.022 3.627 3.627 0 012.656-2.657z"
        fill="#005CF4"
      />
      <path
        d="M14.862 13.139a3.628 3.628 0 002.656-2.657 3.628 3.628 0 00-2.656 2.657z"
        fill="#186EF9"
      />
      <path
        d="M14.862 14.86a3.626 3.626 0 002.656 2.657 3.627 3.627 0 00-2.656-2.656z"
        fill="#0246F2"
      />
      <path
        d="M13.139 13.139a3.627 3.627 0 00-2.657-2.657 3.627 3.627 0 002.657 2.657z"
        fill="#0046F8"
      />
      <path
        d="M13.139 14.86a3.628 3.628 0 00-2.657 2.657 3.628 3.628 0 002.657-2.656z"
        fill="#0729F1"
      />
      <path
        d="M13.139 13.139a3.62 3.62 0 001.723 0 3.627 3.627 0 012.656-2.656A3.62 3.62 0 0014 6a3.622 3.622 0 00-3.518 4.482 3.628 3.628 0 012.657 2.656z"
        fill="#7ABDF7"
      />
      <path
        d="M14.862 14.86a3.62 3.62 0 00-1.723 0 3.628 3.628 0 01-2.656 2.657 3.623 3.623 0 107.035 0 3.626 3.626 0 01-2.656-2.656z"
        fill="#0D74F5"
      />
      <path
        d="M18.38 10.378c-.29 0-.58.035-.861.104a3.625 3.625 0 01-2.656 2.656 3.62 3.62 0 000 1.723 3.626 3.626 0 012.656 2.656 3.622 3.622 0 10.86-7.14v.001z"
        fill="#4494F7"
      />
      <path
        d="M13.14 14.86a3.623 3.623 0 000-1.722 3.626 3.626 0 01-2.657-2.656 3.623 3.623 0 10-1.625 7.058 3.623 3.623 0 001.625-.022 3.627 3.627 0 012.656-2.657z"
        fill="#005CF4"
      />
      <path
        d="M14.862 13.139a3.628 3.628 0 002.656-2.657 3.628 3.628 0 00-2.656 2.657z"
        fill="#186EF9"
      />
      <path
        d="M14.862 14.86a3.626 3.626 0 002.656 2.657 3.627 3.627 0 00-2.656-2.656z"
        fill="#0246F2"
      />
      <path
        d="M13.139 13.139a3.627 3.627 0 00-2.657-2.657 3.627 3.627 0 002.657 2.657z"
        fill="#0046F8"
      />
      <path
        d="M13.139 14.86a3.628 3.628 0 00-2.657 2.657 3.628 3.628 0 002.657-2.656z"
        fill="#0729F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.524 6.884L15.4 2.194a2.8 2.8 0 00-2.8 0l-8.125 4.69a2.8 2.8 0 00-1.4 2.425v9.382a2.8 2.8 0 001.4 2.424l8.125 4.69a2.8 2.8 0 002.8 0l8.124-4.69a2.8 2.8 0 001.4-2.424V9.309a2.8 2.8 0 00-1.4-2.425zM16 1.154a4 4 0 00-4 0L3.875 5.846a4 4 0 00-2 3.464v9.382a4 4 0 002 3.464L12 26.845a4 4 0 004 0l8.124-4.69a4 4 0 002-3.465V9.31a4 4 0 00-2-3.465L16 1.155z"
        fill="#7ABDF7"
      />
    </svg>
  )
}

export default SvgChainOecLogo
