function SvgChainHecoLogo(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.155a4 4 0 014 0l8.124 4.69a4 4 0 012 3.464v9.382a4 4 0 01-2 3.464L16 26.845a4 4 0 01-4 0l-8.124-4.69a4 4 0 01-2-3.464V9.309a4 4 0 012-3.464L12 1.155z"
        fill="#fff"
        stroke="#85C08B"
        strokeWidth={0.6}
      />
      <g fill="#3E8445">
        <path d="M11.329 8.638v1.717a6.466 6.466 0 00-3.324 2.77V8h2.677c.357 0 .647.285.647.638z" />
        <path d="M15.948 10.812v1.646c0 .907-.746 1.642-1.667 1.642h-.544c-1.326 0-2.402 1.058-2.405 2.366v4.598h-2.68a.642.642 0 01-.648-.637v-3.895A5.232 5.232 0 018 16.327c0-3.048 2.508-5.52 5.603-5.52.07 0 .139.002.208.005h2.137z" />
        <g>
          <path d="M16.813 20.426v-1.717a6.465 6.465 0 003.324-2.77v5.125H17.46a.643.643 0 01-.646-.638z" />
          <path d="M12.193 18.252v-1.645c0-.908.747-1.643 1.668-1.643h.543c1.326 0 2.403-1.058 2.405-2.366V8h2.681c.358 0 .647.285.647.638v3.895c.004.067.004.135.004.205 0 3.047-2.508 5.52-5.603 5.52a5.56 5.56 0 01-.208-.005h-2.137v-.001z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgChainHecoLogo
